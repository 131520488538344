<template>
    <div class="details">
        <div class="tap">
            <div class="main">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>网站地图</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="content main">
            <div class="title">网站地图</div>
            <ul>
                <li>
                    <div class="tit" style="font-weight: 700;">首页</div>
                </li>
                <li v-for="(val,index) in list" :key="index">
                    <div class="tit" style="font-weight: 700;">{{val.tap}}</div>
                    <div class="tips">
                        <span v-for="(item,ind) in val.arr" :key="ind" @click="navClick(item.path,item.index)">{{item.name}}</span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            list:[
                    {
                        tap:'简介',
                        arr:[
                                { name:'公司简介',path:'/introduce',index:0 },
                                { name:'使命及愿景',path:'/introduce',index:1 },
                                { name:'发展历程',path:'/introduce',index:2 },
                                { name:'业务架构',path:'/introduce',index:3 },
                                { name:'企业文化',path:'/introduce',index:4 },
                                { name:'办公地点',path:'/introduce',index:5 },
                            ],
                    },
                    {
                        tap:'业务',
                        arr:[
                                { name:'面向用户',path:'/work',index:0 },
                                { name:'面向政企',path:'/work',index:1 },
                                { name:'依蓝云定制',path:'/work',index:2 },
                            ],
                    },
                    {
                        tap:'员工',
                        arr:[
                                { name:'人才发现',path:'/staff',index:0 },
                                { name:'依蓝云学院',path:'/staff',index:1 },
                                { name:'工作环境',path:'/staff',index:2},
                                { name:'员工活动',path:'/staff',index:3 },
                            ],
                    },
                    {
                        tap:'企业责任',
                        arr:[
                                { name:'全球战疫',path:'/enterprise',index:0 },
                                { name:'助力高考',path:'/enterprise',index:1 },
                                { name:'践行承诺',path:'/enterprise',index:2 },
                            ],
                    },
                    {
                        tap:'联系我们',
                        arr:[
                                { name:'售后服务',path:'/service',index:5},
                                { name:'商务咨询',path:'/service',index:7 },
                            ],
                    },
                    {
                        tap:'法律信息',
                        arr:[
                                { name:'服务协议',path:'/service',index:6 },
                                { name:'隐私政策',path:'/service',index:8 },
                            ],
                    },
                    {
                        tap:'其他',
                        arr:[
                                { name:'法律声明',path:'/service',index:9 },
                                { name:'明德准则',path:'/service',index:10 },
                            ],
                    },
            ]
        }
    },
    mounted () {
        if(this.$route.query.type){
            this.type=this.$route.query.type
        }
    },
    methods: {
        navClick(path,index){

                this.$router.push({
                    path:path,
                    query:{
                        herfIndex:index
                    }
                });
            
        },
    }
}
</script>
<style lang="less" scoped>
    .details{
        padding-top: 70px;
        padding-bottom: 80px;
        .tap{
            background-color: #f8f8f8;
            /deep/.el-breadcrumb__item{
                padding: 23px 0;
                font-size: 16px;
            }
            /deep/.el-breadcrumb__separator[class*=icon]{
                margin:0 31px;
            }
            /deep/.el-breadcrumb__inner{
                position: relative;
                color: #009cff;
            }
            /deep/.el-breadcrumb__inner::after{
                content: '';
                position: absolute;
                left: 50%;
                bottom: -23px;
                transform: translateX(-50%);
                width: 32px;
                height: 3px;
                background-color: #009cff;
            }
            /deep/.el-breadcrumb__inner.is-link{
                color: #333;
                font-weight: normal;
            }
            /deep/.el-breadcrumb__inner.is-link::after{
                display: none;
            }
        }

        .content{
            .title{
                margin-top: 70px;
                font-size: 36px;
                padding-bottom: 40px;
                border-bottom: 1px solid #e6e6e6;
            }
            ul{
                li{
                    margin-top:40px;
                    font-size: 16px;
                    .tit{
                        margin-bottom: 16px;
                    }
                    span{
                        cursor: pointer;
                        display: inline-block;
                        width: 140px;
                        color: rgba(0, 0, 0, 0.7);
                    }
                    span:hover{
                        color: #009cff;
                    }
                }
            }
        }

    }
</style>